import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

const PageNotFound = ({ t }) => {
	return (
		<Box>
			<h2>{t('headerText')}</h2>
			<p>{t('contentText')}</p>
		</Box>
	);
};

PageNotFound.propTypes = {
	t: PropTypes.func
};

export default withTranslation('pagenotfound')(PageNotFound);
