import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import TextInput from '../../components/TextInput';

import { shipmentInformation } from '../../containers/ShipmentInformation/shipmentInformationSlice';

import helperImage from '../../assets/sub-num-help.jpeg';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	styled
} from '@mui/material';

const StyledFormBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	padding: '25px'
}));

const StyledButton = styled(Button)(({ theme }) => ({
	width: 300,
	fontSize: '1.2em',
	fontWeight: 'bold',
	color: theme.palette.common.white
}));

const defaultValues = {
	firstName: '',
	lastName: '',
	address1: '',
	address2: '',
	city: '',
	state: '',
	zipCode: ''
};

const ShipmentInformation = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { handleSubmit, control, trigger } = useForm({
		defaultValues
	});
	const [openSubNumberDialog, setOpenSubNumberDialog] = React.useState(false);
	const [selectedProgram, setSelectedProgram] = React.useState('');

	const {
		data: requestShipment,
		error: requestShipmentError,
		hasCompleted: requestShipmentHasCompleted
	} = useSelector((state) => state.requestKitShipmentSlice.requestKitShipment);

	const {
		data: shipmentInfo,
		error: shipmentInfoError,
		hasCompleted: shipmentInfoHasCompleted
	} = useSelector(
		(state) => state.shipmentInformationSlice.shipmentInformation
	);

	React.useEffect(() => {
		if (!requestShipment || !requestShipmentHasCompleted) {
			navigate('/home');
		}
	}, []);

	React.useEffect(() => {
		if (shipmentInfo && shipmentInfoHasCompleted) {
			navigate('/home');
		}
	}, []);

	React.useEffect(() => {
		if (
			!requestShipmentError &&
			requestShipment &&
			requestShipmentHasCompleted
		) {
			navigate('/shipmentInfo');
		}
	}, [requestShipmentError, requestShipment, requestShipmentHasCompleted]);

	const validateField = async (fieldName) => {
		try {
			await trigger(fieldName);
		} catch (err) {
			console.log('err', err);
		}
	};

	const handleProgramChange = (event) => {
		setSelectedProgram(event.target.value);
	};

	const onSubmit = (newData, e) => {
		e.preventDefault();
		dispatch(
			shipmentInformation({
				firstName: newData.firstName,
				lastName: newData.lastName,
				address1: newData.address1,
				address2: newData.address2,
				city: newData.city,
				state: newData.state,
				zipCode: newData.zipCode,
				subscriberNumber: requestShipment.request.subscriberNumber,
				selectedProgram
			})
		);
	};
	return (
		<React.Fragment>
			{requestShipment?.response && requestShipment?.response.length > 1 ? (
				<Typography component='p' sx={{ margin: '15px 0' }}>
					Our records indicate that you belong to multiple savings programs.
					Please select the program below of the printed kit you would like to
					receive. Once you have selected the kit, provide us with your mailing
					information so that we may ship your kit directly to you. then, Click
					the &quot;SUBMIT&quot; button to initiate the shipping process. Your
					kit will be shipped free via the U.S. Postal Service with 7-14 days.
					If you would like to receive a printed version of another kit, please
					go back to the <Link to='/home'>Saving Kit Request</Link> page and
					start over.
				</Typography>
			) : (
				<Typography component='p' sx={{ margin: '15px 0' }}>
					Please provide us with your mailing information so that we may ship
					your kit directly to you. then, Click the &quot;SUBMIT&quot; button to
					initiate the shipping process. Your kit will be shipped free via the
					U.S. Postal Service with 7-14 days. If you would like to receive a
					printed version of another kit, please go back to the{' '}
					<Link to='/home'>Saving Kit Request</Link> page and start over.
				</Typography>
			)}
			<StyledFormBox component='section'>
				<Typography component='p' sx={{ color: '#ff0000' }}>
					* = {t('home:formElements.requiredField')}
				</Typography>
				{shipmentInfoError && (
					<Typography component='p' sx={{ color: '#ff0000' }}>
						{shipmentInfoError}
					</Typography>
				)}
				<Box width='100%' mt={2}>
					<FormControl required>
						<FormLabel id='program-select-label'>Select Program</FormLabel>
						<RadioGroup
							row
							aria-labelledby='program-select-label'
							name='program-select'
							value={selectedProgram}
							onChange={handleProgramChange}
						>
							{requestShipment?.response &&
								requestShipment?.response.map((program, key) => {
									return (
										<FormControlLabel
											key={key}
											value={program}
											control={<Radio />}
											label={program}
										/>
									);
								})}
						</RadioGroup>
					</FormControl>
				</Box>
				<Stack
					direction='row'
					flexWrap='wrap'
					justifyContent='space-between'
					spacing={2}
				>
					<Box sx={{ width: '45%' }}>
						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') }
							}}
							name='firstName'
							control={control}
							label='First Name*'
							onChangeValidate={() => validateField('firstName')}
						/>
						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') }
							}}
							name='lastName'
							control={control}
							label='Last Name*'
							onChangeValidate={() => validateField('lastName')}
						/>
						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') }
							}}
							name='address1'
							control={control}
							label='Address 1*'
							onChangeValidate={() => validateField('address1')}
						/>
						<TextInput
							name='address2'
							control={control}
							label='Address 2'
							onChangeValidate={() => validateField('address2')}
						/>
					</Box>
					<Box>
						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') }
							}}
							name='city'
							control={control}
							label='City*'
							onChangeValidate={() => validateField('city')}
						/>
						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') }
							}}
							name='state'
							control={control}
							label='State*'
							onChangeValidate={() => validateField('state')}
						/>
						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') },
								maxLength: 9
							}}
							name='zipCode'
							type='number'
							control={control}
							label={`${t('home:formElements.zipCode')}*`}
							onChangeValidate={() => validateField('zipCode')}
						/>
					</Box>

					<Box
						width='100%'
						sx={{
							display: 'flex',
							flexDirection: 'column',
							flexWrap: 'wrap',
							alignItems: 'center'
						}}
					>
						<StyledButton
							variant='contained'
							color='success'
							size='large'
							onClick={handleSubmit(onSubmit)}
						>
							Submit
						</StyledButton>
					</Box>
				</Stack>
			</StyledFormBox>
			<Typography sx={{ fontSize: '0.7em' }}>
				{t('home:disclosureText')}
			</Typography>
			<Dialog
				open={openSubNumberDialog}
				onClose={() => setOpenSubNumberDialog(false)}
				aria-labelledby='subscriber-number-help-title'
				aria-describedby='subscriber-number-help-description'
			>
				<DialogContent>
					<img src={helperImage} alt={t('home:subscriberHelperAltText')} />
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenSubNumberDialog(false)}>
						{t('common:buttons.close')}
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

ShipmentInformation.propTypes = {
	t: PropTypes.func
};

export default withTranslation(['home', 'common'])(ShipmentInformation);
