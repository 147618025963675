import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	doNotSell: {
		data: {},
		isLoading: false,
		hasCompleted: false,
		error: undefined
	}
};

export const doNotSellSlice = createSlice({
	name: 'doNotSell',
	initialState,
	reducers: {
		doNotSell: (state) => {
			state.doNotSell.isLoading = true;
			state.doNotSell.error = undefined;
		},
		doNotSellCompleted: (state, action) => {
			state.doNotSell.isLoading = false;
			state.doNotSell.hasCompleted = true;
			state.doNotSell.data = action.payload;
		},
		doNotSellFailed: (state, action) => {
			state.doNotSell.isLoading = false;
			state.doNotSell.error = action.payload;
		},
		doNotSellReset: (state) => {
			state.doNotSell = initialState.doNotSell;
		}
	}
});

export const {
	doNotSell,
	doNotSellCompleted,
	doNotSellFailed,
	doNotSellReset
} = doNotSellSlice.actions;

export default doNotSellSlice.reducer;
