import React from 'react';
import { Link } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import PropTypes from 'prop-types';

import TextInput from '../../components/TextInput';

import {
	doNotSell,
	doNotSellReset
} from '../../containers/DoNotSell/doNotSellSlice';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';

const StyledFormBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	padding: '25px'
}));

const StyledButton = styled(Button)(({ theme }) => ({
	width: 300,
	fontSize: '1.2em',
	fontWeight: 'bold',
	color: theme.palette.common.white
}));

const defaultValues = {
	firstName: '',
	lastName: '',
	address1: '',
	address2: '',
	city: '',
	state: '',
	zip: '',
	primaryEmail: '',
	primaryPhone: '',
	secondaryPhone: '',
	comments: ''
};

const DoNotSell = ({ t }) => {
	const dispatch = useDispatch();

	const { handleSubmit, control, trigger, reset } = useForm({
		defaultValues
	});
	const [messageDetails, setMessageDetails] = React.useState({});
	const [openMessageDialog, setOpenMessageDialog] = React.useState(false);
	const [ipAddress, setIPAddress] = React.useState('');

	const { hasCompleted, error, data } = useSelector(
		(state) => state.doNotSellSlice.doNotSell
	);

	React.useEffect(() => {
		dispatch(doNotSellReset());
		reset();
		getIP();
	}, []);

	React.useEffect(() => {
		if (!error && data && hasCompleted) {
			handleMessageDialog('Information successfully submitted', 'success');
		} else if (error) {
			handleMessageDialog(
				'There was an error processing the information, please try again',
				'error'
			);
		}
	}, [error, data, hasCompleted]);

	const getIP = async () => {
		const res = await axios.get('https://api.ipify.org/?format=json');
		setIPAddress(res.data.ip);
	};

	const validateField = async (fieldName) => {
		try {
			await trigger(fieldName);
		} catch (err) {
			console.log('err', err);
		}
	};

	const handleMessageDialog = (message, status) => {
		setMessageDetails({ status, message });
		setOpenMessageDialog(true);
	};

	const onSubmit = (data) => {
		dispatch(
			doNotSell({
				siteId: '115',
				formUrl: location.origin,
				firstName: data.firstName,
				lastName: data.lastName,
				address1: data.address1,
				address2: data.address2,
				city: data.city,
				state: data.state,
				zip: data.zip,
				primaryEmail: data.primaryEmail,
				primaryPhone: data.primaryPhone,
				secondaryPhone: data.secondaryPhone,
				comments: data.comments,
				ipAddress,
				originType: 'web'
			})
		);
	};

	return (
		<Box component='article'>
			<p>
				<Trans i18nKey='donotsell:contentText.paragraph1'>
					Sempris believes in our members&apos; privacy rights and your right to
					control your personal information. You can find a copy of our privacy
					policy by visiting&nbsp;
					<Link to='http://www.sempris.com/privacy' target='_blank'>
						www.sempris.com/privacy
					</Link>
					. If you so choose, you may request that Sempris NOT sell your
					personal information to third parties. In order to opt-out of the sale
					of your personal information, please complete as much of the
					information requested below as is possible to assist Sempris in
					completing your request. Alternatively, you may call toll-free
					800-475-1942 or send an email with your request to
					<Link to='mailto:privacy@sempris.com'>privacy@sempris.com</Link> and
					we will assist you with your opt-out request.
				</Trans>
			</p>

			<p>
				<Trans i18nKey='donotsell:contentText.paragraph2'>
					If you are a resident of California, you have additional rights under
					the California Consumer Privacy Act of 2018 (CCPA), subject to certain
					exceptions and limitations. Those rights include the following for all
					verifiable requests: i) disclosure by Sempris of the categories and
					specific pieces of personal information we collect as well as certain
					other information about our business practices, the business and
					commercial purposes for collecting, using and sharing your personal
					information, the categories of sources from where we collect personal
					information, the categories of third parties to whom we sell personal
					information, and the categories of third parties we disclose personal
					information for a business purpose; and, ii) the deletion of certain
					personal information which Sempris has collected about you. Sempris is
					required under CCPA to verify the identity of the individual
					requesting disclosure of specific data elements and/or to have their
					data deleted from Sempris&apos; database. Completing as much of the
					information below, along with your specific request within the text
					field, will assist us in addressing your request. Alternatively, you
					may call toll-free 800-475-1942 or send an email with your request to{' '}
					<Link to='mailto:privacy@sempris.com'>privacy@sempris.com</Link> and
					we will assist you with your request.
				</Trans>
			</p>

			<p>
				<Trans i18nKey='donotsell:contentText.paragraph3'>
					<strong>IMPORTANT NOTE</strong>: If you are disabled and unable to
					complete this form, you may call toll-free 800-475-1942 and one of our
					privacy experts will assist you with your request. In addition, if you
					are submitting this form on behalf of another individual as an
					authorized agent, you must mail a written affidavit, signed by the
					individual for whom you are submitting the request. Please mail your
					signed affidavit to: Sempris Privacy Department, Sempris, LLC, P.O.
					Box 5034, Hopkins, MN 55343-1034, Attn: California Privacy Rights.
				</Trans>
			</p>
			<StyledFormBox component='section'>
				<Stack
					direction='row'
					flexWrap='wrap'
					justifyContent='space-between'
					spacing={2}
				>
					<Box sx={{ width: '100%' }}>
						<Typography
							component='p'
							sx={{ color: (theme) => theme.palette.error.main }}
						>
							* = {t('donotsell:formElements.requiredField')}
						</Typography>
					</Box>
					<Box sx={{ width: '45%' }}>
						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') }
							}}
							control={control}
							name='firstName'
							label={`${t('donotsell:formElements.firstName')}*`}
							onChangeValidate={() => validateField('firstName')}
						/>

						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') }
							}}
							control={control}
							name='lastName'
							label={`${t('donotsell:formElements.lastName')}*`}
							onChangeValidate={() => validateField('lastName')}
						/>

						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') }
							}}
							control={control}
							name='address1'
							label={`${t('donotsell:formElements.address1')}*`}
							onChangeValidate={() => validateField('address1')}
						/>

						<TextInput
							control={control}
							name='address2'
							label={t('donotsell:formElements.address2')}
						/>

						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') }
							}}
							control={control}
							name='city'
							label={`${t('donotsell:formElements.city')}*`}
							onChangeValidate={() => validateField('city')}
						/>
					</Box>
					<Box sx={{ width: '45%' }}>
						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') }
							}}
							control={control}
							name='state'
							label={`${t('donotsell:formElements.state')}*`}
							onChangeValidate={() => validateField('state')}
						/>

						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') },
								maxLength: 9
							}}
							control={control}
							name='zip'
							type='number'
							label={`${t('donotsell:formElements.zip')}*`}
							onChangeValidate={() => validateField('zip')}
						/>

						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') }
							}}
							control={control}
							name='primaryEmail'
							label={`${t('donotsell:formElements.primaryEmailAddress')}*`}
							onChangeValidate={() => validateField('primaryEmail')}
						/>

						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') },
								maxLength: 10
							}}
							control={control}
							name='primaryPhone'
							type='number'
							label={`${t('donotsell:formElements.primaryPhoneNumber')}*`}
							onChangeValidate={() => validateField('primaryPhone')}
						/>

						<TextInput
							rules={{
								maxLength: 10
							}}
							control={control}
							name='secondaryPhone'
							type='number'
							label={t('donotsell:formElements.secondaryPhoneNumber')}
						/>
					</Box>
					<Box sx={{ width: '100%' }}>
						<TextInput
							control={control}
							name='comments'
							label={t('donotsell:formElements.comments')}
							multiline
							rows={5}
						/>
					</Box>
					<Box
						width='100%'
						sx={{
							textAlign: 'center'
						}}
					>
						<StyledButton
							variant='contained'
							color='success'
							size='large'
							onClick={handleSubmit(onSubmit)}
						>
							{t('common:buttons.submit')}
						</StyledButton>
					</Box>
				</Stack>
			</StyledFormBox>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={openMessageDialog}
				onClose={() => setOpenMessageDialog(false)}
			>
				<SnackbarContent
					sx={{
						backgroundColor: (theme) =>
							messageDetails.status === 'success'
								? theme.palette.success.main
								: theme.palette.error.main
					}}
					message={
						<Typography component='p' variant='h5'>
							{messageDetails.message}
						</Typography>
					}
				/>
			</Snackbar>
		</Box>
	);
};

DoNotSell.propTypes = {
	t: PropTypes.func
};

export default withTranslation(['donotsell', 'common'])(DoNotSell);
