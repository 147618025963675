import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	shipmentInformation: {
		data: {},
		isLoading: false,
		hasCompleted: false,
		error: undefined
	}
};

export const shipmentInformationSlice = createSlice({
	name: 'shipmentInformation',
	initialState,
	reducers: {
		shipmentInformation: (state) => {
			state.shipmentInformation.isLoading = true;
			state.shipmentInformation.error = undefined;
		},
		shipmentInformationCompleted: (state, action) => {
			state.shipmentInformation.isLoading = false;
			state.shipmentInformation.hasCompleted = true;
			state.shipmentInformation.data = action.payload;
		},
		shipmentInformationFailed: (state, action) => {
			state.shipmentInformation.isLoading = false;
			state.shipmentInformation.error = action.payload;
		},
		shipmentInformationReset: (state) => {
			state.shipmentInformation = initialState.shipmentInformation;
		}
	}
});

export const {
	shipmentInformation,
	shipmentInformationCompleted,
	shipmentInformationFailed,
	shipmentInformationReset
} = shipmentInformationSlice.actions;

export default shipmentInformationSlice.reducer;
