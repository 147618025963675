import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import StyledLink from '../../StyledLink';

import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

const year = new Date().getFullYear();

const Footer = ({ t }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	return (
		<Box component='footer' sx={{ textAlign: 'center', margin: '15px 0' }}>
			<Typography component='p'>
				<StyledLink to='/donotsell'>
					{t('common:footerLinks.doNotSell')}
				</StyledLink>
			</Typography>
			<Typography component='p'>
				<StyledLink onClick={handleClick}>
					{t('common:footerLinks.seniorHelpLine')}
				</StyledLink>
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
				>
					<Box
						sx={{ padding: '15px', textAlign: 'center', fontWeight: 'bold' }}
					>
						<p>{t('common:footerLinks.seniorHelpDialog.doYouHave')}</p>
						<p>{t('common:footerLinks.seniorHelpDialog.pleaseCall')}</p>
						<p>1-866-801-4986</p>
					</Box>
				</Popover>
			</Typography>
			<Typography component='p' sx={{ fontSize: '0.8em' }}>
				&copy; {year} Sempris, LLC. All rights reserved.
			</Typography>
		</Box>
	);
};

Footer.propTypes = {
	t: PropTypes.func
};

export default withTranslation('common')(Footer);
