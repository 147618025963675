import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	requestKitShipment: {
		data: {},
		isLoading: false,
		hasCompleted: false,
		error: undefined
	}
};

export const requestKitShipmentSlice = createSlice({
	name: 'requestKitShipment',
	initialState,
	reducers: {
		requestKitShipment: (state) => {
			state.requestKitShipment.isLoading = true;
			state.requestKitShipment.error = undefined;
		},
		requestKitShipmentCompleted: (state, action) => {
			state.requestKitShipment.isLoading = false;
			state.requestKitShipment.hasCompleted = true;
			state.requestKitShipment.data = action.payload;
		},
		requestKitShipmentFailed: (state, action) => {
			state.requestKitShipment.isLoading = false;
			state.requestKitShipment.error = action.payload;
		},
		requestKitShipmentReset: (state) => {
			state.requestKitShipment = initialState.requestKitShipment;
		}
	}
});

export const {
	requestKitShipment,
	requestKitShipmentCompleted,
	requestKitShipmentFailed,
	requestKitShipmentReset
} = requestKitShipmentSlice.actions;

export default requestKitShipmentSlice.reducer;
