import React from 'react';
import { Provider } from 'react-redux';
import Router from './components/Router';
import { store } from './store';
import Theme from './components/Theme';
import './i18n';

const App = () => {
	return (
		<Provider store={store}>
			<Theme>
				<Router />
			</Theme>
		</Provider>
	);
};

export default App;
