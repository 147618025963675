import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material';

const StyledInput = styled(TextField)(({ theme }) => ({
	'& .MuiOutlinedInput-root': {
		backgroundColor: theme.palette.common.white
	}
}));

const TextInput = ({
	name,
	control,
	label,
	multiline,
	rows,
	helperText,
	rules,
	type,
	onChangeValidate
}) => {
	return (
		<FormControl
			fullWidth
			sx={{
				margin: '15px 0'
			}}
		>
			<Controller
				name={name}
				control={control}
				rules={{
					...rules
				}}
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<StyledInput
						helperText={error ? error.message : helperText}
						size='small'
						margin='normal'
						error={!!error}
						onChange={(e) => {
							onChange(e);
							if (onChangeValidate) {
								onChangeValidate();
							}
						}}
						value={value}
						fullWidth
						label={label}
						type={type}
						variant='outlined'
						multiline={multiline}
						rows={rows}
					/>
				)}
			/>
		</FormControl>
	);
};

TextInput.propTypes = {
	name: PropTypes.string,
	control: PropTypes.any,
	label: PropTypes.string,
	multiline: PropTypes.any,
	rows: PropTypes.any,
	rules: PropTypes.object,
	helperText: PropTypes.any,
	type: PropTypes.string,
	onChangeValidate: PropTypes.func
};

export default TextInput;
