import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import commonEN from './locales/en/common.json';
import homeEN from './locales/en/home.json';
import pageNotFoundEN from './locales/en/404.json';
import doNotSellEN from './locales/en/donotsell.json';

const resources = {
	en: {
		common: commonEN,
		home: homeEN,
		pagenotfound: pageNotFoundEN,
		donotsell: doNotSellEN
	}
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en',
		debug: false,
		initImmediate: false,
		ns: ['programstop-translation'],
		defaultNS: 'programstop-translation',
		react: {
			useSuspense: false,
			wait: true
		},
		resources,
		interpolation: {
			escapeValue: false
		}
	});
