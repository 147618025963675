import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import StyledLink from '../../StyledLink';

import semprisLogo from '../../../assets/sempris_logo.png';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

const ContentFooter = ({ t }) => {
	return (
		<Box>
			<Box sx={{ textAlign: 'right', marginTop: '15px' }}>
				<img src={semprisLogo} width={150} alt='sempris' />
			</Box>
			<Box component='nav'>
				<List
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center'
					}}
				>
					<ListItem sx={{ width: 'auto', padding: '0 15px' }}>
						<StyledLink to='/'>{t('common:navigation.home')}</StyledLink>
					</ListItem>
					<ListItem
						sx={{
							width: 'auto',
							padding: '0'
						}}
					>
						<Divider orientation='vertical' flexItem />
					</ListItem>
					<ListItem sx={{ width: 'auto', padding: '0 15px' }}>
						<StyledLink to='mailto:memberservices@onlinemembershipservices.com'>
							{t('common:navigation.contactUs')}
						</StyledLink>
					</ListItem>
				</List>
			</Box>
		</Box>
	);
};

ContentFooter.propTypes = {
	t: PropTypes.func
};

export default withTranslation('common')(ContentFooter);
