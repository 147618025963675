import { configureStore } from '@reduxjs/toolkit';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { catchError } from 'rxjs/operators';

// SLICES
import requestKitShipmentSlice from '../containers/RequestKitShipment/requestKitShipmentSlice';
import doNotSellSlice from '../containers/DoNotSell/doNotSellSlice';
import shipmentInformationSlice from '../containers/ShipmentInformation/shipmentInformationSlice';

// EPICS
import requestKitShipmentEpic from '../containers/RequestKitShipment/requestKitShipmentEpics';
import doNotSellEpic from '../containers/DoNotSell/doNotSellEpics';
import shipmentInformationEpic from '../containers/ShipmentInformation/shipmentInformationEpic';

const epicMiddleware = createEpicMiddleware();

const epics = combineEpics(
	...requestKitShipmentEpic,
	...shipmentInformationEpic,
	...doNotSellEpic
);

const rootEpic = (action$, store$, dependencies) =>
	epics(action$, store$, dependencies).pipe(
		catchError((error, source) => {
			console.error(error);
			return source;
		})
	);

export const store = configureStore({
	reducer: {
		requestKitShipmentSlice,
		doNotSellSlice,
		shipmentInformationSlice
	},
	middleware: [epicMiddleware]
});

epicMiddleware.run(rootEpic);
